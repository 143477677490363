// import simplebar from 'simplebar';
// const scrollbar = new simplebar(document.getElementsByClassName('newScrollbar')[0], {
// 	autoHide: false,
// });
// scrollbar.getScrollElement().addEventListener('scroll', (s) => {
// 	// check if at the end of the scroll
// 	if (s.target.scrollTop + s.target.clientHeight >= s.target.scrollHeight) {
// 		console.log('end of scroll');
// 		$.fn.fullpage.setAllowScrolling(true);
// 	}
// 	// check if at the start of the scroll
// 	if (s.target.scrollTop === 0) {
// 		console.log('start of scroll');
// 		$.fn.fullpage.setAllowScrolling(true);
// 	}
// });
// $('.newScrollbar').on({
// 	mouseenter: () => {
// 		// console.log('enter');
// 		$.fn.fullpage.setAllowScrolling(false);
// 	},
// 	mouseleave: () => {
// 		// console.log('leave');
// 		$.fn.fullpage.setAllowScrolling(true);
// 	},
// });
// import 'simplebar/dist/simplebar.css';
import './vendor/fullpage.js';
import each from 'async-each';
import './vendor/maxImage.js';
import './vegas/vegas.js';
// /vid/Website_Startscreen_Video_19-07-21_Moment.jpg import the image
import mainPicture from '../vid/Website_Startscreen_Video_19-07-21_Moment.jpg';

$(() => {
	// 0. Init console to avoid error
	var method;
	var noop = function () {};
	var methods = [
		'assert',
		'clear',
		'count',
		'debug',
		'dir',
		'dirxml',
		'error',
		'exception',
		'group',
		'groupCollapsed',
		'groupEnd',
		'info',
		'log',
		'markTimeline',
		'profile',
		'profileEnd',
		'table',
		'time',
		'timeEnd',
		'timeStamp',
		'trace',
		'warn',
	];
	var length = methods.length;
	var console = (window.console = window.console || {});
	var contextWindow = $(window);
	var $root = $('html, body');
	while (length--) {
		method = methods[length];
		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}

	// 1. Background image as data attribut
	var list = $('.bg-img');
	for (var i = 0; i < list.length; i++) {
		var src = list[i].getAttribute('data-image-src');
		list[i].style.backgroundImage = "url('" + src + "')";
		list[i].style.backgroundRepeat = 'no-repeat';
		list[i].style.backgroundPosition = 'center';
		list[i].style.backgroundSize = 'cover';
	}
	// Background color as data attribut
	var list = $('.bg-color');
	for (var i = 0; i < list.length; i++) {
		var src = list[i].getAttribute('data-bgcolor');
		list[i].style.backgroundColor = src;
	}

	// 2. Init Coutdown clock
	try {
		// check if clock is initialised
		$('.clock-countdown').downCount({
			date: $('.site-config').attr('data-date'),
			offset: +10,
		});
	} catch (error) {
		// Clock error : clock is unavailable
		console.log('clock disabled/unavailable');
	}

	// 3. Show/hide menu when icon is clicked
	var menuItems = $('.all-menu-wrapper .nav-link');
	var menuIcon = $('.menu-icon, #navMenuIcon');
	var menuBlock = $('.all-menu-wrapper');
	var reactToMenu = $('.page-main, .navbar-sidebar, .page-cover');
	var menuLinks = $('.navbar-mainmenu a, .navbar-sidebar a');
	// Menu icon clicked
	menuIcon.on('click', function () {
		menuIcon.toggleClass('menu-visible');
		menuBlock.toggleClass('menu-visible');
		menuItems.toggleClass('menu-visible');
		reactToMenu.toggleClass('menu-visible');
		return false;
	});

	// Hide menu after a menu item clicked
	menuLinks.on('click', function () {
		menuIcon.removeClass('menu-visible');
		menuBlock.removeClass('menu-visible');
		menuItems.removeClass('menu-visible');
		reactToMenu.removeClass('menu-visible');
		return true;
	});

	// 4 Carousel Slider
	new Swiper('.carousel-swiper-beta-demo .swiper-container', {
		pagination: '.carousel-swiper-beta-demo .items-pagination',
		paginationClickable: '.carousel-beta-alpha-demo .items-pagination',
		nextButton: '.carousel-swiper-beta-demo .items-button-next',
		prevButton: '.carousel-swiper-beta-demo .items-button-prev',
		loop: true,
		grabCursor: true,
		centeredSlides: true,
		autoplay: 5000,
		autoplayDisableOnInteraction: false,
		slidesPerView: 1,
		spaceBetween: 0,
		breakpoints: {
			1024: {
				slidesPerView: 1,
			},
			800: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
			640: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
			440: {
				slidesPerView: 1,
				spaceBetween: 0,
			},
		},
	});
	// 4.1 Slideshow slider
	var imageList = $('.slide-show .img');
	var imageSlides = [];
	for (var i = 0; i < imageList.length; i++) {
		var src = imageList[i].getAttribute('data-src');
		imageSlides.push({ src: src });
	}
	$('.slide-show').vegas({
		delay: 5000,
		shuffle: true,
		slides: imageSlides,
		animation: ['kenburnsUp', 'kenburnsDown', 'kenburnsLeft', 'kenburnsRight'],
	});

	// 5. Init video background
	var videoBg = $('.video-container video, .video-container object');

	// 6. Prepare content for animation
	$('.section .content .anim.anim-wrapped').wrap("<span class='anim-wrapper'></span>");

	// 7. Init fullPage.js plugin
	var pageSectionDivs = $('.page-fullpage .section');
	var headerLogo = $('.header-top .logo');
	var bodySelector = $('body');
	var sectionSelector = $('.section');
	var headerContainer = $('.hh-header');
	var slideElem = $('.slide');
	var arrowElem = $('.p-footer .arrow-d');
	var pageElem = $('.section');
	var pageSections = [];
	var pageAnchors = [];
	var nextSectionDOM;
	var nextSection;
	var fpnavItem;
	var mainPage = $('#mainpage');
	// var sendEmailForm = $(".send_email_form");
	// var sendMessageForm = $(".send_message_form");
	// var scrollOverflow = true;
	var css3 = true;
let pausedByUser = false;
	// disable scroll overflow on small device
	if (contextWindow.width() < 601) {
		// scrollOverflow = false;
		css3 = false;
	}
	if (contextWindow.height() < 480) {
		// scrollOverflow = false;
		css3 = false;
	}

	// pss-lb: disable side-scroll overflow on small device
	var touchSens = 45;
	if (contextWindow.width() < 601) {
		touchSens = 100;
	}
	if (contextWindow.width() >= 601) {
		touchSens = 30;
	}

	// Get sections name
	for (var i = 0; i < pageSectionDivs.length; i++) {
		pageSections.push(pageSectionDivs[i]);
	}
	// window.asyncEach(
	each(
		pageSections,
		function (pageSection, cb) {
			var anchor = pageSection.getAttribute('data-section');
			pageAnchors.push(anchor + '');
			cb();
		},
		function (err) {
			let disableColorChange = false;
			// Init plugin
			if (mainPage.width()) {
				// config fullpage.js
				// console.log(pageAnchors);
				mainPage.fullpage({
					// new fullpage("#mainpage", {
					// menu: '#qmenu',
					anchors: pageAnchors,
					verticalCentered: false,
					css3: css3,
					navigation: true,
					responsiveWidth: 995,
					responsiveHeight: 800,
					// scrollOverflow: scrollOverflow,
					// scrollOverflowOptions: {
					// 	click: true,
					// 	submit: true,
					// },
					// pss-lb:  variable for device custom behaviour
					touchSensitivity: touchSens,
					normalScrollElements: '.section .scrollable',
					afterRender: function () {
						// Fix video background
						videoBg.maximage('maxcover');

						// Fix for internet explorer : adjust content height
						// Detect IE 6-11
						var isIE = /*@cc_on!@*/ false || !!document.documentMode;
						if (isIE) {
							var contentColumns = $('.section .content .c-columns');
							contentColumns.height(contextWindow.height());
							for (var i = 0; i < contentColumns.length; i++) {
								if (contentColumns[i].height <= contextWindow.height()) {
									contentColumns[i].style.height = '100vh';
								}
							}
						}

						// init contact form
						// Default server url
						// var newsletterServerUrl = "./ajaxserver/serverfile.php";
						// var messageServerUrl = "./ajaxserver/serverfile.php";

						// Use form define action attribute
						// if (sendEmailForm.attr("action") && sendEmailForm.attr("action") != "") {
						// 	newsletterServerUrl = sendEmailForm.attr("action");
						// }
						// if (sendMessageForm.attr("action") && sendMessageForm.attr("action") != "") {
						// 	messageServerUrl = sendMessageForm.attr("action");
						// }

						// sendEmailForm.initForm({
						// 	serverUrl: newsletterServerUrl,
						// });
						// sendMessageForm.initForm({
						// 	serverUrl: messageServerUrl,
						// });
						//!NEW set KeyboardScrolling
						$.fn.fullpage.setKeyboardScrolling(false);
						//if device is mobile
						if (contextWindow.width() < 601) {
							// console.log("contextWindow.width < 601");
							$('#legal-footer').addClass('fade-out');
							$('#social-footer').addClass('fade-out');
						}

						let nav_container = document.getElementById('nav-icon3');
						nav_container.addEventListener('click', () => {
							if (disableColorChange) return;
							$('#nav-icon3').toggleClass('yobo_blue');
						});
						let overlayToggle = document.getElementById('overlayToggle');
						overlayToggle.addEventListener('click', (e) => {
							// console.log('click away');
							if (disableColorChange) return;
							$('#nav-icon3').toggleClass('yobo_blue');
						});
					},
					afterResize: function () {
						var pluginContainer = $(this);
						$.fn.fullpage.reBuild();
					},
					onLeave: function (index, nextIndex, destination) {
						// Behavior when a full page is leaved
						arrowElem.addClass('gone');
						pageElem.addClass('transition');
						slideElem.removeClass('transition');
						pageElem.removeClass('transition');
						try {
							$('#qr-app-scan').popover('hide');
						} catch (error) {}
					},
					afterLoad: function (anchorLink, index) {
						// Behavior after a full page is loaded
						// hide or show clock
						if ($('.section.active').hasClass('hide-clock')) {
							headerContainer.addClass('gone');
						} else {
							headerContainer.removeClass('gone');
						}
					},
					onSlideLeave: function (section, origin, destination, direction, trigger) {
						if (section === 'analyse-finder' && destination === 0 && contextWindow.width() > 995) {
							// $('#logo_light').removeClass('hidden');
							// $('#logo_dark').addClass('hidden');

							$('#page_logo').removeClass();
							$('#page_logo').addClass('fill_white');

							// class="container nav-container"
							$('.nav-secondary, .nav-main').removeClass('active');
							$('#nav-icon3').removeClass('yobo_blue');

							$('#legal-footer').addClass('light-text');
							disableColorChange = true;
						} else if (contextWindow.width() > 995) {
							// $('#logo_light').addClass('hidden');
							// $('#logo_dark').removeClass('hidden');
							$('#page_logo').removeClass();
							$('#page_logo').addClass('fill_black');

							// class="container nav-container"
							$('.nav-secondary, .nav-main').addClass('active');
							$('#nav-icon3').addClass('yobo_blue');

							$('#legal-footer').removeClass('light-text');
							disableColorChange = false;
						}
					},
					afterLoad: (origin, destination, direction) => {
						if (destination == 1 && contextWindow.width() > 995) {
							disableColorChange = true;
						} else {
							disableColorChange = false;
						}

						if (destination == 1 && contextWindow.width() > 601) {
							// document.getElementById('backgroundVideo').play();
							// $('#qmenu').addClass('fade-out').removeClass('fade-in');
							$('#legal-footer').addClass('fade-out').removeClass('fade-in');
							$('#social-footer').addClass('fade-out').removeClass('fade-in');

							$('#sideNavContainer').addClass('fade-out').removeClass('fade-in');

							$('#fp-nav').addClass('fade-out').removeClass('fade-in');

							if(!pausedByUser) {
								document.getElementById('heroVideo').play();
								// change icon
								$('#video-background-toggle').find('i').removeClass('fa-play-circle').addClass('fa-image');
							}
				
							// } else if (destination !== 1 && $('#qmenu').hasClass('fade-out')) {
						} else if (destination !== 1 && contextWindow.width() > 601) {
							// console.log($('#video-background-toggle').find('i'));
							// $('#video-background-toggle').find('i').removeClass('fa-play-circle').addClass('fa-pause-circle');
							// $('#video-background-toggle')
							// 	.find('i')
							// 	.removeClass('fa-play-circle')
							// 	.addClass('fa-play-circle');
							// $('#qmenu').addClass('fade-in').removeClass('fade-out');

							$('#legal-footer').addClass('fade-in').removeClass('fade-out');
							$('#social-footer').addClass('fade-in').removeClass('fade-out');

							$('#sideNavContainer').addClass('fade-in').removeClass('fade-out');

							$('#fp-nav').addClass('fade-in').removeClass('fade-out');
						}

						if (destination === 1 && contextWindow.width() > 995) {
							// $('#logo_light').removeClass('hidden');
							// $('#logo_dark').addClass('hidden');
							$('#page_logo').removeClass();
							$('#page_logo').addClass('fill_white');
							$('.navbar-brand').css('opacity', '0');
							$('.navbar-brand').css('pointer-events', 'none');

							// class="container nav-container"
							$('.nav-secondary, .nav-main').removeClass('active');
							$('#nav-icon3').removeClass('yobo_blue');
						} else if (destination !== 1 && contextWindow.width() > 995) {
							// $('#logo_light').addClass('hidden');
							// $('#logo_dark').removeClass('hidden');
							$('#page_logo').removeClass();
							$('#page_logo').addClass('fill_black');
							$('.navbar-brand').css('opacity', '1');
							$('.navbar-brand').css('pointer-events', 'auto');

							$('.nav-secondary, .nav-main').addClass('active');
							$('#nav-icon3').addClass('yobo_blue');
						}
						if (contextWindow.width() < 995) {
							$('#page_logo').removeClass();
							$('#page_logo').addClass('fill_blue');

							$('.nav-secondary, .nav-main').addClass('active');
							$('#nav-icon3').addClass('yobo_blue');
						}

						if (destination == 7 && contextWindow.width() < 601) {
							$('#legal-footer').addClass('fade-in').removeClass('fade-out');
							// $('#social-footer').addClass('fade-in').removeClass('fade-out');
						} else if (destination != 7 && contextWindow.width() < 601) {
							$('#legal-footer').addClass('fade-out');
							// $('#social-footer').addClass('fade-out').removeClass('fade-in');
						}
					},
				});
			}
		},
	);
	// Scroll to fullPage.js next/previous section
	$('.scrolldown a, .scroll.down').on('click', function () {
		try {
			// fullpage scroll
			$.fn.fullpage.moveSectionDown();
		} catch (error) {
			// normal scroll
			$root.animate(
				{
					scrollTop: window.innerHeight,
				},
				400,
				function () {},
			);
		}
	});

	// 8. Hide some ui on scroll
	// var scrollHeight = $(document).height() - contextWindow.height();
	// contextWindow.on('scroll', function () {
	// 	var scrollpos = $(this).scrollTop();
	// 	var siteHeaderFooter = $('.page-footer, .page-header');

	// 	var footerBackground = $('.gradientFooter');
	// // if (scrollpos > 10 && scrollpos < scrollHeight - 100) {
	// if (scrollpos > 100) {
	// 	siteHeaderFooter.addClass("scrolled");
	// 	footerBackground.addClass("scrolled");
	// }
	// else {
	// 	siteHeaderFooter.removeClass("scrolled");
	// 	footerBackground.removeClass("scrolled");
	// }
	// });

	// 9. Page Loader : hide loader when all are loaded
	contextWindow.on('load', function () {
		$('#page-loader').addClass('p-hidden');
		$('.section').addClass('anim');

		//display overly after load
		// $('#cookieconsent-overlay').css('display', 'inline');
	});

	// 10. Hide footer on scroll (disable funktion 8. if in use)
	// var scrollPos = 0;
	// var upCount = 0;
	// var downCount = 0;

	// window.addEventListener('scroll', function () {
	// 	var siteHeaderFooter = $('.page-footer, .page-header');
	// 	//.cc-bottom is for the "Datenschutz Einstellungen"
	// 	var footerBackground = $('.gradientFooter, .cc-bottom');

	// 	if ((document.body.getBoundingClientRect()).top > scrollPos) {
	// 		upCount++;
	// 		//change for how long user needs to scroll
	// 		if (upCount >= 30) {
	// 			siteHeaderFooter.removeClass("scrolled");
	// 			footerBackground.removeClass("scrolled");
	// 			downCount = 0;
	// 		}
	// 	}
	// 	else {
	// 		downCount++;
	// 		//threshold for smooth touch useability
	// 		// lb: changed to 60, so it stays on mobil even with top spring back effekt
	// 		if (downCount >= 60) {
	// 			siteHeaderFooter.addClass("scrolled");
	// 			footerBackground.addClass("scrolled");
	// 			upCount = 0;
	// 		}
	// 	}
	// 	scrollPos = (document.body.getBoundingClientRect()).top;
	// });

	//* 11. toggle video playback on click
	$('#video-background-toggle').on('click', function () {
		//get i of element
		var toggleIcon = $(this).find('i');
		// console.log(toggleIcon);
		// var toggleIcon = $(this);
		// console.log(toggleIcon);
		var video = document.getElementById('heroVideo');
		// console.log(video);
		if (video.paused) {
			video.play();
			pausedByUser = false;
			toggleIcon.removeClass('fa-play-circle').addClass('fa-image');

			// setTimeout(() => {
				// var videoContainer = document.getElementById('video-overlay');
				// videoContainer.style.backgroundImage = 'none';

				// var videoContainer = document.getElementById('video-overlay');
				// // console.log(videoContainer);
				// videoContainer.style.backgroundImage = 'url(' + poster + ')';
				// //style if fullscreen
				// videoContainer.style.backgroundSize = 'cover';
				// videoContainer.style.backgroundPosition = 'center center';
				// videoContainer.style.backgroundRepeat = 'no-repeat';
			// }, 50);
		} else {
			video.pause();

			pausedByUser = true;
			toggleIcon.removeClass('fa-image').addClass('fa-play-circle');

			setTimeout(() => {
				//* reset video to start
				// video.currentTime = 0;

				//* get data-poster from video
				// var poster = video.getAttribute('data-poster');
				//* use data-poster as background with pure js

				// var videoContainer = document.getElementById('video-overlay');
				// console.log(videoContainer);
				// videoContainer.style.backgroundImage = 'url(' + poster + ')';
				// videoContainer.style.backgroundImage = 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(' + poster + ')';

				// videoContainer.style.backgroundImage =
				// 	'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(' + mainPicture + ')';

				// 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(/vid/Website_Startscreen_Video_19-07-21_Moment.jpg)';
				// 'linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(/assets/Website_Startscreen_Video_19-07-21_Moment.55409357.jpg)';

				// videoContainer.style.backgroundSize = 'cover';
				// videoContainer.style.backgroundPosition = 'center top';
				// videoContainer.style.backgroundRepeat = 'no-repeat';
			}, 50);
			// my test
		}

		//* set state of video so model wont restart it if it was paused by the user
		// previouseState = false;
		// console.log('%chmmmmmmmm ', 'color: red; font-size: 20px;');
		// previouseState = false;
		// console.log({ previouseState });
	});
});
