// import lazySizes from 'lazysizes';
// // lazySizes.init();
// // throttleDelay
// lazySizes.cfg.throttleDelay = 10;

import LazyLoad from 'vanilla-lazyload';
const lazyLoadInstance = new LazyLoad({
	elements_selector: '.lazyload',
	// ... more custom settings?
	cancel_on_exit: false
});
