window.addEventListener('load', () => {
	//get cookie data
	const cookie = document.cookie.split('; ').find((row) => row.startsWith('cookieconsent_status='));
	// console.log(cookie);

	if (cookie === undefined) {
		// console.log("cookieValue is NOT set");
		return;
	} else if (cookie.split('=')[1] === 'allow') {
		// console.log("cookieValue is set to -> allow");
		initialiseFeedbackButton();
		document.querySelector('.jfFeedbackToggle i').style.display = 'inline-block';
	} else {
		// console.log("cookieValue is set to -> deny");
	}

	function initialiseFeedbackButton() {
		//create async script in head
		let scriptJF = document.createElement('script');
		scriptJF.src = 'https://form.jotform.com/cardforms/feedbackEmbedButton.min.js';
		document.head.append(scriptJF);

		setTimeout(function () {
			new JF_FeedbackEmbedButton({
				buttonText: 'Feedback',
				buttonFontColor: '#000000',
				buttonBackgroundColor: '#00000000',
				buttonSide: 'Bottom',
				buttonAlign: 'Right',
				buttonIcon: 'thumbsUp',
				base: 'https://form.jotform.com/',
				formId: 210057227604346
			});
			document.getElementsByClassName('jfFeedbackButton-text')[0].innerHTML = '';
			document.getElementsByClassName('jfFeedbackButton-icon')[0].style.display = 'none';
		}, 1000);
	}

	$('.jfFeedbackToggle').on('click', function () {
		// var elemDataONE = document.querySelector('[id^="JF_feedback_embed"]');
		var elemDataALL = document.querySelectorAll('[id^="JF_feedback_embed"]');
		var selected = elemDataALL[1].id;
		// console.log(selected);
		document.getElementById(selected).classList.toggle('jFisOpen');
	});
});
