import './jf-feedback-block.js';

//* main video speed
//! not working
// $(function () {
// 	document.getElementById('backgroundVideo').playbackRate = 0.7;
// });

//* Safari Broken Shadow Remover - SBSR
try {
	window.onload = function () {
		// Get the user-agent string
		let userAgentString = navigator.userAgent;
		// Detect Chrome
		let chromeAgent = userAgentString.indexOf('Chrome') > -1;
		// Detect Safari
		let safariAgent = userAgentString.indexOf('Safari') > -1;
		// Discard Safari since it also matches Chrome
		if (chromeAgent && safariAgent) safariAgent = false;

		if (safariAgent) {
			// document.getElementById("bma1Pic").classList.remove('shadow-drop');
			// document.getElementById('bma1PicMobil').classList.remove('shadow-drop');
			document.getElementById('bma2Pic').classList.remove('shadow-drop');
			// document.getElementById('bma2PicMobil').classList.remove('shadow-drop');
		}
	};
} catch (error) {
	console.log(error);
}

//* ----------------------------------------------------------------
// <!-- Change Headline Text -->

var text = ['kraftvoll & ausdauernd', 'schlank & attraktiv', 'gelassen & entspannt'];
var counter = 0;
var elem = document.getElementById('changeText');
// var inst = setInterval(change, 5000);

// function change() {
// 	//* transition time => 300ms
// 	elem.classList.add('fadeOut');
// 	setTimeout(function () {
// 		elem.classList.remove('fadeOut');
// 		elem.innerHTML = text[counter];
// 		counter++;
// 		if (counter >= text.length) {
// 			counter = 0;
// 			// clearInterval(inst); // uncomment to stop refreshing after one cycle
// 		}
// 	}, 600);
// }
//* ----------------------------------------------------------------
//both big sections
$('#trigger1,#trigger2').click(function () {
	$('#turn1').toggleClass('example1');
	$('#turn2').toggleClass('example2');
});

//each card
$('#t1,#t2,#t3,#t4,#t5,#t6,#t7,#t8,#t9').click(function () {
	$(this).toggleClass('simpleFlip');
});
//* ----------------------------------------------------------------
//En-/disable scroll
$('.turnOn').on('click', function () {
	$.fn.fullpage.setAllowScrolling(true);

	document.getElementsByTagName('BODY')[0].style.overflow = 'visible';

	// window.onscroll = function() {};
	// console.log("toggle main ON");
});
$('.turnOff').on('click', function () {
	$.fn.fullpage.setAllowScrolling(false);

	document.getElementsByTagName('BODY')[0].style.overflow = 'hidden';

	// scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	// scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
	// window.onscroll = function() {
	//     window.scrollTo(scrollLeft, scrollTop);
	// };
	// console.log("toggle main OFF");
});

//* ----------------------------------------------------------------
// <!-- disable touch scrolling over element -->

// var fixed = document.getElementsByClassName('fixed');
// fixed[0].addEventListener('touchmove', function(e) {
//         e.preventDefault();
// }, false);

document.querySelectorAll('.fixedScroll').forEach((item) => {
	item.addEventListener(
		'touchmove',
		function (e) {
			e.preventDefault();
		},
		false,
	);
});
//* ----------------------------------------------------------------
// var video = document.getElementById("videoPlayer");
// function stopVideo(){
//     video.pause();
//     // video.currentTime = 0;
// }
// $(".stopVideo").on('click', function(){
//     stopVideo();
// });

$(function () {
	$('#VIDEOansZiel')
		.modal({
			show: false,
		})
		.on('hidden.bs.modal', function () {
			$(this).find('video')[0].pause();
			// console.log('test1');
		});
});
$(function () {
	$('#VIDEO-Athletik')
		.modal({
			show: false,
		})
		.on('hidden.bs.modal', function () {
			$(this).find('video')[0].pause(); //* self pause video
			// visibleModal('VIDEO-Athletik')

			// console.log({ pausedByUser });
			if (pausedByUser === false) {
				document.getElementById('backgroundVideo').play();
			}
		});
});
$(function () {
	$('#VIDEO-Gesundheit')
		.modal({
			show: false,
		})
		.on('hidden.bs.modal', function () {
			$(this).find('video')[0].pause();

			// console.log({ pausedByUser });
			if (pausedByUser === false) {
				document.getElementById('backgroundVideo').play();
			}
		});
});
//* ----------------------------------------------------------------
// <!-- Tooltip -->

$(function () {
	$('[data-toggle="tooltip"]').tooltip({
		// html: true,
		boundary: 'viewport',
		// container: 'body',
		trigger: 'manual',
		delay: { show: 0, hide: 2000 },
		placement: $(window).width() > 800 ? 'left' : 'top',
	});
});
//* -------------------------------modal scripts---------------------------------
//* newsletter

//* show on load
// $(document).ready(function () {
// 	$("#newsletterModal").modal("show");
// });

//* reset newsletterModal
$('#newsletterModal').on('hidden.bs.modal', function (event) {
	document.getElementById('submit-newsletter').innerHTML = 'Eintragen';
	$('#newsletter-recipient-name').val('');
	$('#newsletter-recipient-email').val('');
	$('#newsletter-mailCheckBox').prop('checked', false);
});

//* hide tooltip on focus
$('#newsletter-recipient-name').on('focus', function () {
	$('#newsletter-recipient-name').tooltip('hide');
});
$('#newsletter-recipient-email').on('focus', function () {
	$('#newsletter-recipient-email').tooltip('hide');
});
$('#newsletter-mailCheckBox').on('focus', function () {
	$('#newsletter-mailCheckBox').tooltip('hide');
});

//* email vaildation
function validateEmail(email) {
	var re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

//* post function
async function postDataJSON(url = '', data = {}) {
	const response = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data), // body data type must match "Content-Type" header
	});
	// return response.json();
	// return response.status;
	return response;
}

//* check data and submit
// var timeoutID = null;
// $('#submit-newsletter').on('click', function (event) {
// 	clearTimeout(timeoutID);
// 	var check = true;
// 	//* submitbutton for animation
// 	const submitButton = document.getElementById('submit-newsletter');
// 	//* check
// 	// if ($("#newsletter-recipient-name").val() == "") {
// 	// 	$("#newsletter-recipient-name").tooltip("show");
// 	// 	check = false;
// 	// }
// 	if ($('#newsletter-recipient-email').val() == '') {
// 		// $("#newsletter-recipient-email").tooltip("show");
// 		$('#newsletter-recipient-email').attr('data-original-title', 'Bitte ausfüllen').tooltip('show');
// 		check = false;
// 	} else if (!validateEmail($('#newsletter-recipient-email').val())) {
// 		$('#newsletter-recipient-email').attr('data-original-title', 'Bitte trage eine gültige E-Mail-Adresse ein.').tooltip('show');
// 		check = false;
// 	}
// 	if ($('#newsletter-mailCheckBox:checked').val() !== 'true') {
// 		$('#newsletter-mailCheckBox').tooltip('show');
// 		check = false;
// 	}
// 	timeoutID = setTimeout(() => {
// 		// $("#newsletter-recipient-name").tooltip("hide");
// 		$('#newsletter-recipient-email').tooltip('hide');
// 		$('#newsletter-mailCheckBox').tooltip('hide');
// 	}, 2000);

// 	if (check == true) {
// 		//* set sessionStorage
// 		// if (sessionStorage.getItem("Analyse-Finder-RequestCounter")) {
// 		//     //* if not empty, add 1
// 		//     sessionStorage.setItem(
// 		//         "Analyse-Finder-RequestCounter",
// 		//         Number(sessionStorage.getItem("Analyse-Finder-RequestCounter")) +
// 		//             1
// 		//     );
// 		// } else {
// 		//     //* if empty, set 1
// 		//     sessionStorage.setItem("Analyse-Finder-RequestCounter", 1);
// 		// }

// 		//* trigger animation
// 		// const submitButton = document.getElementById("submit-newsletter");
// 		submitButton.innerHTML = '<i class="fad fa-circle-notch fa-spin"></i>';
// 		// setTimeout(() => {
// 		//     submitButton.innerHTML = '<i class="fas fa-check"></i>';
// 		// }, 1000);

// 		var formData = {
// 			// productName: cuttedCardName.trim(),
// 			// productLink: window.location.href + productLink,
// 			name: $('#newsletter-recipient-name').val(),
// 			email: $('#newsletter-recipient-email').val()
// 		};
// 		console.log(formData);

// 		//* submit
// 		postDataJSON('https://enut07i4of6vucq.m.pipedream.net', formData)
// 			.then((data) => {
// 				// if(data.status)
// 				// console.log(data.body);
// 				// console.log(data.status);
// 				// if (data.status == 403) {
// 				// 	alert(
// 				// 		"Die maximale Anzahl an Requests wurde erreicht. Bitte versuche es später noch einmal."
// 				// 	);
// 				// } else if (data.status === 200) {
// 				// 	alert(
// 				// 		"Vielen Dank für deine Anfrage. Eine E-Mail ist unterwegs zu dir!"
// 				// 	);
// 				// }

// 				//* trigger animation
// 				// const submitButton = document.getElementById("submit-newsletter");
// 				// // submitButton.addEventListener("click", function () {
// 				// // submitIcon.classList.add("fad fa-circle-notch fa-spin");
// 				// submitButton.innerHTML =
// 				//     '<i class="fad fa-circle-notch fa-spin"></i>';
// 				// setTimeout(() => {
// 				//     submitButton.innerHTML = '<i class="fas fa-check"></i>';
// 				// }, 1000);

// 				// setTimeout(() => {
// 				//     // $("#newsletterModal").modal("hide");
// 				//     $("#newsletter-recipient-name").val("");
// 				//     $("#newsletter-recipient-email").val("");
// 				//     $("#newsletter-mailCheckBox").prop("checked", false);
// 				// }, 1800);

// 				if (data.status == 200) {
// 					submitButton.innerHTML = '<i class="fas fa-check"></i>';
// 					setTimeout(() => {
// 						$('#newsletterModal').modal('hide');
// 					}, 1300);
// 				} else if (data.status == 403) {
// 					// submitButton.innerHTML = '<i class="fas fa-check"></i>';
// 					alert('Die maximale Anzahl an Requests wurde erreicht. Bitte versuche es später noch einmal.');
// 				}
// 			})
// 			.catch((error) => {
// 				console.log(error);
// 			});

// 		// console.log(
// 		// 	sessionStorage.getItem("Analyse-Finder-RequestCounter")
// 		// );
// 		// if (sessionStorage.getItem("Analyse-Finder-RequestCounter") <= 5) {
// 		// //* close modal and reset values
// 		// $("#newsletterModal").modal("hide");
// 		// $("#newsletter-recipient-name").val("");
// 		// $("#newsletter-recipient-email").val("");
// 		// $("#newsletter-mailCheckBox").prop("checked", false);
// 		// } else {
// 		//     alert("Die maximale Anzahl an Requests wurde erreicht.");
// 		//     //* close modal and reset values
// 		//     $("#mailModal").modal("hide");
// 		//     $("#newsletter-recipient-name").val("");
// 		//     $("#newsletter-recipient-email").val("");
// 		//     $("#newsletter-mailCheckBox").prop("checked", false);
// 		// }
// 	}
// });
//* ----------------------------------------------------------------
//* onclick for all elements by class ion-arrow-down-b
$('.ion-arrow-down-b').click(function () {
	$('#t1').removeClass('attention-bounce');
	$('#t5').removeClass('attention-bounce');
});
//* ----------------------------------------------------------------
//* Analyse Sharing

// $(document).ready(function () {
// 	$("#mailModal").modal("show");
// });

//* handel open mailModal
$('#mailModal').on('show.bs.modal', function (event) {
	var selectedCard = document.getElementsByClassName('activeCard');
	// console.log(document.getElementsByClassName("activeCard"));

	// var cardName = document
	// 	.getElementsByClassName("activeCard")[0]
	// 	.getElementsByClassName("item-name")[0].innerHTML;
	// var cuttedCardName = cardName.slice(cardName.indexOf("4") + 1).replace("<br>", " ");
	// console.log(cuttedCardName);

	//* fill cardname in modal
	// document.getElementById("mailModalTitle").innerHTML = cuttedCardName;
});

//* reset mailModal
$('#mailModal').on('hidden.bs.modal', function (event) {
	// document.getElementById("submit").innerHTML = "Versenden";
	$('#recipient-name').val('');
	$('#recipient-email').val('');
	$('#mailCheckBox').prop('checked', false);
	const submitButton = document.getElementById('submit');
	submitButton.innerHTML = 'Versenden';
});

//* hide tooltip on focus
$('#recipient-name').on('focus', function () {
	$('#recipient-name').tooltip('hide');
});
$('#recipient-email').on('focus', function () {
	$('#recipient-email').tooltip('hide');
});
$('#mailCheckBox').on('focus', function () {
	$('#mailCheckBox').tooltip('hide');
});

//* email vaildation
// function validateEmail(email) {
// 	var re =
// 		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// 	return re.test(String(email).toLowerCase());
// }

//* post function
async function postDataFORM(url, data) {
	const response = await fetch(url, {
		method: 'POST',
		body: data,
	});
	return response;
}

//* check data and submit
var timeoutID = null;
var buttonEnabled = true;
//* Analyse Sharing
$('#submit').on('click', function (event) {
	console.log(buttonEnabled);
	document.getElementById('analyseMailErrorText').innerHTML = '';

	if (!buttonEnabled) return;
	buttonEnabled = false;
	clearTimeout(timeoutID);
	var check = true;
	//* check
	// if ($("#recipient-name").val() == "") {
	// 	$("#recipient-name").tooltip("show");
	// 	check = false;
	// }
	if ($('#recipient-email').val() == '') {
		// $("#recipient-email").tooltip("show");
		$('#recipient-email').attr('data-original-title', 'Dieses Feld ist notwenig').tooltip('show');
		check = false;
	} else if (!validateEmail($('#recipient-email').val())) {
		$('#recipient-email')
			.attr('data-original-title', 'Bitte tragen Sie eine gültige E-Mail-Adresse ein.')
			.tooltip('show');
		check = false;
	}
	if ($('#mailCheckBox:checked').val() !== 'true') {
		$('#mailCheckBox').tooltip('show');
		check = false;
	}
	timeoutID = setTimeout(() => {
		// $("#recipient-name").tooltip("hide");
		$('#recipient-email').tooltip('hide');
		$('#mailCheckBox').tooltip('hide');
	}, 2000);
	if (check) {
		//* set sessionStorage
		// if (sessionStorage.getItem("Analyse-Finder-RequestCounter")) {
		// 	//* if not empty, add 1
		// 	sessionStorage.setItem(
		// 		"Analyse-Finder-RequestCounter",
		// 		Number(sessionStorage.getItem("Analyse-Finder-RequestCounter")) +
		// 			1
		// 	);
		// } else {
		// 	//* if empty, set 1
		// 	sessionStorage.setItem("Analyse-Finder-RequestCounter", 1);
		// }
		var cardName = document.getElementsByClassName('activeCard')[0].dataset.name;

		var cuttedCardName = cardName
			// .slice(cardName.indexOf("4") + 1)
			.replace('<br>', '');

		var productLink = '';
		try {
			// productLink = document
			// 	.getElementsByClassName("activeCard")[0]
			// 	.getElementsByClassName("item-footer-primary")[0]
			// 	.getElementsByTagName("a")[0]
			// 	.getAttribute("href");
			productLink = document.getElementsByClassName('activeCard')[0].dataset.productlink;
		} catch (error) {
			console.log('no link');
		}
		const formData = new FormData();
		// var formData = {
		// 	productName: cuttedCardName.trim(),
		// 	productLink: window.location.origin + productLink,
		// 	// name: $("#recipient-name").val(),
		// 	email: $("#recipient-email").val(),
		// };
		formData.append('productName', cuttedCardName.trim());
		formData.append('productLink', window.location.origin + '/' + productLink);
		// formData.append("name", $("#recipient-name").val());
		formData.append('email', $('#recipient-email').val());

		const submitButton = document.getElementById('submit');
		submitButton.innerHTML = '<i class="fad fa-circle-notch fa-spin"></i>';
		//* submit
		postDataFORM(API_URL + '/analyse-finder-mail/LNa23in501lMwio837887', formData)
			.then((data) => {
				console.log(data);
				console.log(data.status);
				// if(data.status)
				// console.log(data.body);
				// console.log(data.status);
				// if (data.status == 403) {
				// 	alert(
				// 		"Die maximale Anzahl an Requests wurde erreicht. Bitte versuche es später noch einmal."
				// 	);
				// } else if (data.status === 200) {
				// 	alert(
				// 		"Vielen Dank für deine Anfrage. Eine E-Mail ist unterwegs zu dir!"
				// 	);
				// }
				// document.getElementById("#submit").innerHTML
				// console.log(document.getElementById("#submit").innerHTML);
				if (data.status == 200) {
					document.getElementById('analyseMailErrorText').innerHTML = '';

					setTimeout(() => {
						submitButton.innerHTML = '<i class="fas fa-check"></i>';
						// remove "show" from id mailModal
					}, 200);
					setTimeout(() => {
						buttonEnabled = true;
						$('#mailModal').modal('hide');
					}, 1200);
					// setTimeout(() => {
					// 	// $("#mailModal").modal("hide");
					// 	$("#recipient-name").val("");
					// 	$("#recipient-email").val("");
					// 	$("#mailCheckBox").prop("checked", false);
					// }, 1800);
					// });
				} else {
					submitButton.innerHTML = '<i class="fas fa-times"></i>';
					buttonEnabled = true;
					// id=analyseMailErrorText
					document.getElementById('analyseMailErrorText').innerHTML =
						'Fehler beim Senden der E-Mail.';
					setTimeout(() => {
						submitButton.innerHTML = 'Senden';
					}, 1500);
				}
			})
			.catch((error) => {
				buttonEnabled = true;
				console.log(error);
			});

		// console.log(
		// 	sessionStorage.getItem("Analyse-Finder-RequestCounter")
		// );
		// if (sessionStorage.getItem("Analyse-Finder-RequestCounter") <= 5) {
		// 	//* close modal and reset values
		// 	$("#mailModal").modal("hide");
		// 	$("#recipient-name").val("");
		// 	$("#recipient-email").val("");
		// 	$("#mailCheckBox").prop("checked", false);
		// } else {
		// 	alert("Die maximale Anzahl an Requests wurde erreicht.");
		// 	//* close modal and reset values
		// 	$("#mailModal").modal("hide");
		// 	$("#recipient-name").val("");
		// 	$("#recipient-email").val("");
		// 	$("#mailCheckBox").prop("checked", false);
		// }
		document.getElementById('analyseMailErrorText').innerHTML = '';
	} else {
		buttonEnabled = true;
	}
});
//* ----------------------------------------------------------------
$('.hideArrowButton').on('click', function () {
	setTimeout(function () {
		if ($('#hideArrow').css('opacity') == 0) $('#hideArrow').css('opacity', 1);
		// else $('#hideArrow').css('opacity', 0);
	}, 1200);
});

$('.hideArrowAlways').on('click', function () {
	if ($('#hideArrow').css('opacity') == 1) $('#hideArrow').css('opacity', 0);
	// else $('#hideArrow').css('opacity', 0);
});
//* ----------------------------------------------------------------
// <!-- * Page Banner -->
$(function () {
	$('[data-toggle="popover"]').popover();
});
$('#codeBannerPopover').on('shown.bs.popover', function () {
	setTimeout(function () {
		$('#codeBannerPopover').popover('hide');
	}, 1200);
});
//* ----------------------------------------------------------------
